@import '../../styleguide/position';

.Root {
	@include padding($spacer-sm);
	flex: 0 0 100%;
	fill: inherit;
	margin-top: $spacer-md;

	// display: flex;
}

.View {
	width: 100%;
}

.Component {
	fill: rgb(184, 184, 184);
	stroke-width: 0.2px;
	stroke: #000000ff;
}