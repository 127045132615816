@import '../../styleguide/color';
@import '../../styleguide/position';

// .Root {
//   flex: 0 1 100%;
// 	transform: translateZ(0);
// 	display: flex;
// 	overflow: hidden;
// 	align-items: stretch;
// }

.Root {
	// height: 100%;
	// display: flex;
	// width: 100%;
	overflow-y: auto;
	// flex: 1;
	transform: translateZ(0);
	height: calc(100% - #{2 * $detail-height} - #{0 * $spacer-sm});
}

.Results {
	// height: 100%;
	// width: 100%;
	overflow: hidden;
}
