@import '../../styleguide/color';
@import '../../styleguide/position';

.Count {
	font-size: 1.3em;
	text-align: center;
}

.Label {
	margin-right: $spacer-sm;
}

.SketchContainer {
	background-color: $bg-light;
	color: $color-light;
	@include padding($spacer-sm);
	// margin-top: $spacer-md;
}

.SketchTitle {
	font-weight: bold;
	margin-bottom: $spacer-sm;
	font-size: 1.3em;
}