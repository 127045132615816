@import '../../styleguide/position';
@import '../../styleguide/color';

.Message {
	border-radius: 1px;
	text-align: center;
	font-size: 0.8em;
	font-weight: bold;
	background-color: $bg-light;
	color: $color-light;
	opacity: 0;
	@include padding($spacer-md);
}