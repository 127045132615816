@import '../../styleguide/position';
@import '../../styleguide/color';

.Root {
	@include background-dark;
	margin-bottom: $spacer-sm;;
	display: flex;

	height: 26px;
	min-height: 26px;
	border-radius: 1px;
	
	font-family: 'Encode Sans Condensed', sans-serif;
	align-items: center;
	align-self: flex-end;
	cursor: pointer;

	transition: background-color, fill, border, color;
	transition-duration: 400ms;
}

.Inline {
	display: inline-flex;
}

.Default {
	background-color: $bg-dark;
	border: 1px solid $color-dark;
	fill: $color-dark;
	color: $color-dark;

	&:hover {
		background-color: $bg-light;
		fill: $color-light;
		color: $color-light;
	}
}

.Confirm {
	background-color: $bg-light;
	fill: $color-light;
	color: $color-light;
	border: 1px solid $bg-light;

	&:hover {
		background-color: $bg-dark;
		border: 1px solid $color-dark;
		fill: $color-dark;
		color: $color-dark;
	}
}

.Cancel {
	background-color: $bg-dark;
	fill: $color-dark;
	color: $color-dark;
	border: none;
	&:hover {
		background-color: transparentize($bg-light, 0.7);
	}
}