@import '../../styleguide/color';
@import '../../styleguide/position';
@import '../../styleguide/media';

.Root {
	@include background-dark;
	@include padding($spacer-sm);
	
	height: $navbar-height;
	display: flex;
	align-items: center;

	justify-content: space-between;
	z-index: 100;
}

.Logo {
	fill: $color-dark;
	margin-right: $spacer-md;
	flex: 0 0 100px;

	@include for-size(tablet-portrait-up) {
		flex: auto;
	}
}

.Icons {
	display: flex;
	align-items: center;
}

.AppIdentity {
	display: flex;
	align-items: center;
	color: $color-dark;
	font-size: 1em;
	
	@include for-size(tablet-portrait-up) {
		font-size: 1.2em;
	}
}