$bg-dark: #013a7d;
$color-dark: #ffffff;
$bg-modal-dark: rgba(255, 255, 255, 0.667);

$bg-mid: #f2f2f2;
$color-mid: #000000;
$bg-modal-mid: rgba(0, 0, 0, 0.667);

$bg-light: #ffffff;
$color-light: #000000;
$bg-modal-light: rgba(0, 0, 0, 0.667);


@mixin background-dark {
	background-color: $bg-dark;
	color: $color-dark;
}

@mixin background-input-dark {
	background-color: darken($bg-dark, 10);
	color: $color-dark;
}

@mixin background-mid {
	background-color: $bg-mid;
	color: $color-mid;
}