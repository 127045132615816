@import '../../styleguide/position';
@import '../../styleguide/color';
@import '../../styleguide/media';

.Root {
	@include padding($spacer-sm);
	@include background-mid;
	fill: $bg-mid;

	&:nth-child(even) {
		background-color: lighten($bg-mid, 4);
		fill: lighten($bg-mid, 4);
	}

	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		border-bottom: 1px solid darken($bg-mid, 5);
	}

	@include for-size(tablet-landscape-up) {
		flex-direction: row;
	}


}

.Table {
	flex: 0 0 100%;
	@include for-size(tablet-landscape-up) {
		flex: 1;
	}
}

.TableGrid {
	display: flex;
	flex-wrap: wrap;
}

.SketchContainer {
	flex: 0 0 100%;
	display: flex;
	justify-content: center;
	@include for-size(tablet-landscape-up) {
		flex: 1;
	}
}

