@import '../../styleguide/color';
@import '../../styleguide/position';

.Root {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $bg-modal-dark;
	top: 0;
}

.Content {
	background-color: $bg-dark;
	color: $color-dark;
	@include padding($spacer-md);
	min-width: 80%;
	max-width: 100%;
}

.Heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: $spacer-md;
}


.Title {
	margin: 0;
}

.ActionsContainer {
	display: flex;
	justify-content: flex-end;
}

.Actions {
	flex: 0 1 40%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Match {
	margin: $spacer-md 0;
}