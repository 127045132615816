@import '../../styleguide/position';
@import '../../styleguide/media';

.Root {
	// @include padding(0, $spacer-sm);
	flex: 0 0 50%;
	display: flex;
}

.Label {
	margin-right: $spacer-sm;
	font-size: 0.7em;
	flex: 0 0 90px;
	@include for-size(tablet-landscape-up) {
		font-size: 1em;
		flex: 0 0 150px;
	}
}

.FullWidth {
	flex: 0 0 100%;
}

.Value {
	font-weight: bold;
	text-align: left;
	font-size: 0.7em;
	@include for-size(tablet-landscape-up) {
		font-size: 1em;
	}
}

.ArrayValue {
	display: inline-block;
	margin-right: 0.25em;
}