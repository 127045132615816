@import '../../styleguide/color';
@import '../../styleguide/position';
@import '../../styleguide/media';

.Root {
	@include background-dark;

	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	transform: translate(0%, -120%);
	transition: transform 300ms;

	@include for-size(tablet-landscape-up) {
		transform: translate(100%, 0%);
		transition: none;
	}

	&.Open {
		transform: translate(0%, 0%);
	}
}


.Container {
	@include padding($spacer-sm);
	opacity: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	transition: opacity 500ms;
	transition-delay: 150ms;

	&.Open {
		opacity: 1;
	}
}

.Title {
	font-size: 1.2em;
	font-weight: 700;
	margin-bottom: $spacer-md;
}

.Content {
	margin-bottom: $spacer-md;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.CloseButton {
	display: inline-block;
	@include for-size(tablet-landscape-up) {
		display: none;
	}
}