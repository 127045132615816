@font-face {
  font-family: 'Encode Sans Condensed';
  src: url('./fonts/EncodeSansCondensed-Regular.ttf'),
    url('./fonts/EncodeSansCondensed-Bold.ttf');
} 

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Encode Sans Condensed', sans-serif;
  overflow: hidden;
}
