@import '../../../styleguide/position';

.Root {
	@include padding($spacer-sm);
	flex: 0 0 100%;
	fill: inherit;
	margin-top: $spacer-md;
}

.View {
	height: 215px;
}

.Component {
	fill: rgb(162, 29, 29);
	stroke-width: 0.2px;
	stroke: #000000ff;
}