@import '../../styleguide/position';
@import '../../styleguide/color';
@import '../../styleguide/media';



.Root {
	// @include padding($spacer-sm, $spacer-sm, 0);
	display: flex;
	align-items: center;
	margin-bottom: $spacer-sm;

	@include for-size(desktop-up) {
		margin-bottom: $spacer-md;
	}
}

.Label {
	color: $color-dark;
	font-weight: 600;
	flex: 0 0 50%;
	display: block;
	font-size: 0.7em;

	@include for-size(tablet-portrait-up) {
		flex: 0 0 40%;
		font-size: 1em;
	}

	@include for-size(tablet-landscape-up) {
		font-size: 0.8em;
	}

	@include for-size(desktop-up) {
		font-size: 1em;
	}
}

.InputContainer {
	@include background-input-dark;
	@include padding($spacer-sm);
	// @include padding($spacer-sm);

	flex: 1;
	
	height: 26px;
	align-items: center;

	display: flex;	
}

.Input {
	flex: 1;
	border: none;
	outline: none;
	background-color: transparent;
	font-family: 'Encode Sans Condensed', sans-serif;
	color: $color-dark;

	&::-ms-clear {
		display: none;
	}
}