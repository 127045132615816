$navbar-height: 50px;

$spacer-sm: 5px;
$spacer-md: 10px;
$spacer-lg: 15px;

$detail-height: 30px;

@mixin padding($top, $right: $top, $bottom: $top, $left: $right) {
	padding: $top $right $bottom $left;
	box-sizing: border-box;
}

@mixin margin($top, $right: $top, $bottom: $top, $left: $right) {
	margin: $top $right $bottom $left;
}