@import '../../styleguide/media';

.Root {
	
}

.OpenPanelIcons {
	display: flex;

	&.PanelOpen {
		display: none;
		@include for-size(tablet-landscape-up) {
			display: flex;
		}
	}
}

.ClosePanelIcon {
	display: none;

	&.PanelOpen {
		display: initial;
		@include for-size(tablet-landscape-up) {
			display: none;
		}
	}
}