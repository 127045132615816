@import '../../styleguide/position';


.Content {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.Disclaimer {
	margin-top: auto;
	font-size: 0.6em;
}

.Instructions li {
	line-height: 2rem;
}

.InstructionNotes {
	font-size: 0.6em;
	margin-bottom: $spacer-lg;;
}