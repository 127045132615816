@import '../../styleguide/position';
@import '../../styleguide/media';

.Root {
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
}

.Instructions {
  // @include margin($spacer-sm);
  @include padding($spacer-sm);
  // margin-bottom: $spacer-sm;
  font-weight: 400;
  text-align: center;
  height: $detail-height;
}

.Content {
  position: relative;
  height: calc(100% - #{$navbar-height});

  // overflow-y: hidden;
  // flex-direction: column;
  transform: translateZ(0);
  // justify-content: flex-start;
  
  @include for-size(tablet-landscape-up) {
    display: flex;
    flex-direction: row;
  }
}

.Data {
  height: 100%;
  transform: translateZ(0);
  // flex-direction: column;
  @include for-size(tablet-landscape-up) {
    // display: flex;
    flex: 0 1 65%;
  }
}

.Panels {

  // position: absolute;
  width: 100%;
  height: 100%;
	overflow: hidden;


  @include for-size(tablet-landscape-up) {
    position: relative;
    flex: 0 0 35%;
  }
}