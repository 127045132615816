@import '../../styleguide/color';
@import '../../styleguide/position';

.Count {
	font-size: 1.3em;
	text-align: center;
}

.Label {
	margin-right: $spacer-sm;
}