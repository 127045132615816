@import '../../styleguide/position';

.Root {
	display: flex;
}

.Inline {
	display: inline-flex;
}

.Margin {
	margin: $spacer-md;
}